import { faMicrophone, faVideo } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';

export interface RecordingModeDialogProps {
  open: boolean;
  onChooseMode: (mode: 'audio' | 'video') => void;
  onClose: () => void;
}

export function RecordingModeDialog({ open, onChooseMode, onClose }: RecordingModeDialogProps) {
  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header title="Choose your recording mode" rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Footer>
          <RMButton leftIcon={faMicrophone} fullWidth autoLoading onClick={() => onChooseMode('audio')}>
            Audio
          </RMButton>
          <RMButton leftIcon={faVideo} background="primary" fullWidth autoLoading onClick={() => onChooseMode('video')}>
            Video
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}

import { Listener, Subscription } from '../event-emitter';

export interface QueueItem<T> {
  sequenceId: number;
  payload: T;
}

export interface InternalQueueItem<T, P extends string = string> extends QueueItem<T> {
  partition: P;
  priority: number;
}

export interface PullConfig {
  commitTimeout?: number;
  pullTimeout?: number;
}

export type PartitionQueueEventMap<P extends string> = {
  push: { partition: P; sequenceId: number };
  commit: { partition: P; sequenceIds: number[] };
  commitTimeout: { partition: P; sequenceId: number };
  reset: { partition: P };
  clear: null;
};

export type StringKey<T> = Exclude<keyof T, number | symbol>;

export interface PartitionQueue<T extends Record<string, unknown>> {
  initialize(sessionId: string): void;

  push<P extends StringKey<T>>(partition: P, value: QueueItem<T[P]>, priority?: number): Promise<void>;
  pull<P extends StringKey<T>>(partition: P, config?: PullConfig): Promise<QueueItem<T[P]>>;
  commit<P extends StringKey<T>>(partition: P, sequenceId: number): Promise<QueueItem<T[P]>[]>;
  reset<P extends StringKey<T>>(partition: P): Promise<void>;
  clear(): Promise<void>;
  getLatestSequenceId<P extends StringKey<T>>(partition: P): number | null;

  size<P extends StringKey<T>>(partition?: P, excludePendingItems?: boolean): Promise<number>;
  isEmpty<P extends StringKey<T>>(partition?: P, excludePendingItems?: boolean): Promise<boolean>;

  addListener<P extends StringKey<T>, E extends keyof PartitionQueueEventMap<P>>(
    event: E,
    listener: Listener<E, PartitionQueueEventMap<P>[E]>,
  ): Subscription;
}

export interface PartitionQueueRepository<T extends Record<string, unknown>> {
  addQueueItem<P extends StringKey<T>>(sessionId: string, item: InternalQueueItem<T[P], P>): Promise<void>;
  removeQueueItem<P extends StringKey<T>>(sessionId: string, partition: P, sequenceId: number): Promise<void>;
  getNextQueueItem<P extends StringKey<T>>(
    sessionId: string,
    offset?: number,
  ): Promise<InternalQueueItem<T[P], P> | null>;

  clear(sessionId: string): Promise<void>;
  size<P extends StringKey<T>>(sessionId: string, partition?: P): Promise<number>;

  getLatestSequenceId<P extends StringKey<T>>(sessionId: string, partition: P): number | null;
}

export class PullTimeoutError extends Error {
  constructor() {
    super('Queue pull timeout exceeded');
  }
}

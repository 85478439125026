export enum QueueSynchronizerStateType {
  Empty = 'Empty',
  Ready = 'Ready',
  Finishing = 'Finishing',
  Finished = 'Finished',
  UnrecoverableError = 'UnrecoverableError',
  Destroyed = 'Destroyed',
}

export interface QueueSynchronizerBaseState {
  sessionId: string;
  uploadUrl: string;
}

export interface QueueSynchronizerEmptyState {
  type: QueueSynchronizerStateType.Empty;
}

export interface QueueSynchronizerReadyState extends QueueSynchronizerBaseState {
  type: QueueSynchronizerStateType.Ready;
  outOfStorage: boolean;
}

export interface QueueSynchronizerFinishingState extends QueueSynchronizerBaseState {
  type: QueueSynchronizerStateType.Finishing;
  outOfStorage: boolean;
}

export interface QueueSynchronizerFinishedState extends QueueSynchronizerBaseState {
  type: QueueSynchronizerStateType.Finished;
}

export interface QueueSynchronizerUnrecoverableErrorState {
  type: QueueSynchronizerStateType.UnrecoverableError;
  error: unknown;
}

export interface QueueSynchronizerDestroyedState extends Partial<QueueSynchronizerBaseState> {
  type: QueueSynchronizerStateType.Destroyed;
}

export type QueueSynchronizerState =
  | QueueSynchronizerEmptyState
  | QueueSynchronizerReadyState
  | QueueSynchronizerFinishingState
  | QueueSynchronizerFinishedState
  | QueueSynchronizerUnrecoverableErrorState
  | QueueSynchronizerDestroyedState;

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCircularProgress } from '@/components/RMCircularProgress/RMCircularProgress';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { logger } from '@/logger';
import {
  InterviewPartitionQueueRecord,
  RecorderClientMessage,
  usePendingInterviewSessions,
} from '@/modules/conversation-recorder/interview';
import { DefaultPartitionQueue } from '@/modules/conversation-recorder/partition-queue/partition-queue';
import { IdbPartitionQueueRepository } from '@/modules/conversation-recorder/partition-queue/partition-queue.idb.repository';
import { PartitionQueueProgressObserver } from '@/modules/conversation-recorder/partition-queue/partition-queue.progress.observer';
import { PartitionQueue } from '@/modules/conversation-recorder/partition-queue/partition-queue.types';
import { QueueSynchronizer } from '@/modules/conversation-recorder/queue-synchronizer/queue-synchronizer';
import { QueueSynchronizerStateType } from '@/modules/conversation-recorder/queue-synchronizer/queue-synchronizer.types';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getSigninPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { captureException } from '@/utils/captureException';

import { Button, LeftCard, PageContent, RightCard } from './RecordingRecoveryPage.styles';

enum RecordingRecoveryStateType {
  LOADING = 'loading',
  EMPTY = 'empty',
  RECOVERING = 'recovering',
  RECOVERED = 'recovered',
}

interface RecordingRecoveryLoadingState {
  type: RecordingRecoveryStateType.LOADING;
}

interface RecordingRecoveryEmptyState {
  type: RecordingRecoveryStateType.EMPTY;
}

interface RecordingRecoveryRecoveringState {
  type: RecordingRecoveryStateType.RECOVERING;
  count: number;
  progress: Array<number>;
}

interface RecordingRecoveryRecoveredState {
  type: RecordingRecoveryStateType.RECOVERED;
}

type RecordingRecoveryState =
  | RecordingRecoveryLoadingState
  | RecordingRecoveryEmptyState
  | RecordingRecoveryRecoveringState
  | RecordingRecoveryRecoveredState;

function updateProgressReducer(
  index: number,
  indexProgress: number,
): (state: RecordingRecoveryState) => RecordingRecoveryState {
  return (state) => {
    if (state.type !== RecordingRecoveryStateType.RECOVERING) {
      return state;
    }
    const progress = [...state.progress];
    progress[index] = indexProgress;
    const done = progress.every((p) => p === 1);
    if (done) {
      return { type: RecordingRecoveryStateType.RECOVERED };
    }
    return {
      ...state,
      progress,
    };
  };
}

function InternalRecordingRecoveryPage() {
  const { analyticsService, interviewSessionRepository, localStorageRepository, recordingSessionService } =
    useServices();

  const isMobile = useIsMobileViewport();
  const navigate = useNavigate();
  const user = useUser();
  const [searchParams] = useSearchParams();

  let recorderPersonId = searchParams.get('recorder-person-id');
  const recorderUserId = searchParams.get('recorder-user-id');
  if (recorderPersonId == null && recorderUserId == null) {
    recorderPersonId = user?.personId ?? null;
  }

  const [state, setState] = useState<RecordingRecoveryState>({ type: RecordingRecoveryStateType.LOADING });
  const { refreshPendingSessions } = usePendingInterviewSessions();

  const handleGoToSignIn = useCallback(() => {
    navigate(getSigninPath());
  }, [navigate]);

  const handleGoToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleRecover = useCallback(async () => {
    const finishedSessions = refreshPendingSessions();
    logger.debug('FINISHED_SESSIONS', { finishedSessions });

    if (finishedSessions.length === 0) {
      setState({ type: RecordingRecoveryStateType.EMPTY });
      return;
    }

    setState({
      type: RecordingRecoveryStateType.RECOVERING,
      count: finishedSessions.length,
      progress: finishedSessions.map(() => 0),
    });

    for (const [index, session] of finishedSessions.entries()) {
      logger.debug('RECOVERING_SESSION', { session });

      try {
        const { recordingType, promptId, imageId } = session;

        const queueRepository = new IdbPartitionQueueRepository<InterviewPartitionQueueRecord>(localStorageRepository);
        const size = await queueRepository.size(session.sessionId);
        if (size === 0) {
          logger.debug('SESSION_IS_EMPTY', { session });
          logger.debug('TRIGGERING_PROCESS_JOB', { session });
          try {
            await recordingSessionService.processRecordingSession(session.sessionId, session.promptId);
            logger.debug('PROCESS_JOB_TRIGGERED', { session });
          } catch {
            // The recording may be old and already processed. Just skip it and delete the session from the local store.
            logger.debug('PROCESS_JOB_FAILED', { session });
          }
          interviewSessionRepository.deleteInterviewSession(session.sessionId);
          await queueRepository.clear(session.sessionId);
          updateProgressReducer(index, 1);
          continue;
        }

        const { url } = await recordingSessionService.getRecordingSessionUrl(session.sessionId);
        logger.debug('UPLOAD_URL', { url, session });

        const queue: PartitionQueue<InterviewPartitionQueueRecord> =
          new DefaultPartitionQueue<InterviewPartitionQueueRecord>(queueRepository, Number.MAX_VALUE);
        queue.initialize(session.sessionId);
        const queueSynchronizer = new QueueSynchronizer<RecorderClientMessage, 'recording'>(
          interviewSessionRepository,
          queue,
          'recording',
        );

        const progressObserver = new PartitionQueueProgressObserver(queue);
        await progressObserver.startObserving();
        progressObserver.addListener(({ percentage }) => {
          setState(updateProgressReducer(index, percentage));
        });

        // Initialize the queue
        logger.debug('INITIALIZING_QUEUE_SYNCHRONIZER', { session });
        queueSynchronizer.initialize({
          sessionId: session.sessionId,
          imageId,
          promptId,
          type: recordingType,
          uploadUrl: url,
          recorderUserRuid: analyticsService.getRuid(),
          recorderUserId,
          recorderPersonId,
        });
        queueSynchronizer.finish();
        logger.debug('QUEUE_SYNCHRONIZER_INITIALIZED', { session });

        // Wait for the upload
        logger.debug('WAITING_FOR_QUEUE_SYNCHRONIZATION', { session });
        await queueSynchronizer.waitForType(QueueSynchronizerStateType.Finished);
        await progressObserver.stopObserving();
        logger.debug('QUEUE_SYNCHRONIZATION_COMPLETED', { session });

        // Trigger the process job
        logger.debug('TRIGGERING_PROCESS_JOB', { session });
        await recordingSessionService.processRecordingSession(session.sessionId, session.promptId);
        logger.debug('PROCESS_JOB_TRIGGERED', { session });

        // Done
        logger.debug('SESSION_RECOVERED', { session });
        interviewSessionRepository.deleteInterviewSession(session.sessionId);
        await queueRepository.clear(session.sessionId);
        break;
      } catch (error) {
        captureException(error, true);
        toast('Failed to recover recording, please try again.', 'root-toast', 'error');
      }
    }
  }, [
    analyticsService,
    interviewSessionRepository,
    localStorageRepository,
    recordingSessionService,
    refreshPendingSessions,
    recorderUserId,
    recorderPersonId,
  ]);

  const onceRef = useRef(false);
  useEffect(() => {
    if (state.type !== RecordingRecoveryStateType.LOADING) {
      return;
    }
    if (onceRef.current) {
      return;
    }
    onceRef.current = true;
    handleRecover();
  }, [handleRecover, state.type]);

  return (
    <RecordingLayout.Root
      Header={
        <RecordingLayout.Header>
          {isMobile === false && user === null && <RMButton onClick={handleGoToSignIn}>Log in to Remento</RMButton>}
        </RecordingLayout.Header>
      }
    >
      <PageContent>
        <LeftCard>
          {/* Icon */}
          {state.type == RecordingRecoveryStateType.EMPTY && (
            <RMText type="sans" size="xxxl" color="on-primary" bold style={{ fontSize: '100px' }}>
              ?
            </RMText>
          )}
          {state.type === RecordingRecoveryStateType.RECOVERING && (
            <RMCircularProgress
              size={100}
              progress={state.progress.reduce((total, p) => total + p, 0) / state.progress.length}
            />
          )}
          {state.type === RecordingRecoveryStateType.RECOVERED && <RMCircularProgress size={100} progress={1} />}

          {/* Copy */}
          <RMText type="sans" size="s" color="on-primary" bold align="center">
            {state.type == RecordingRecoveryStateType.EMPTY && 'We didn’t find a recording to upload.'}
            {state.type == RecordingRecoveryStateType.RECOVERING && 'Uploading...'}
            {state.type == RecordingRecoveryStateType.RECOVERED && 'Done!'}
          </RMText>
          <RMText type="sans" size="xs" color="on-primary" align="center">
            {state.type == RecordingRecoveryStateType.EMPTY && 'Make sure you’re on the same browser you recorded on.'}
            {state.type == RecordingRecoveryStateType.RECOVERING &&
              'Give us a moment to upload your remaining recording.'}
            {state.type == RecordingRecoveryStateType.RECOVERED && 'Your recording was fully uploaded!'}
          </RMText>

          {state.type == RecordingRecoveryStateType.RECOVERING && (
            <RMText type="sans" size="l" color="on-primary" bold align="center">
              {Math.floor((state.progress.reduce((total, p) => total + p, 0) / state.progress.length) * 100)}%
            </RMText>
          )}
          {state.type == RecordingRecoveryStateType.RECOVERED && (
            <RMText type="sans" size="l" color="on-primary" bold align="center">
              100%
            </RMText>
          )}
        </LeftCard>
        <RightCard>
          <RMText type="serif" size="l" color="on-surface-primary">
            Re-upload a recording
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            Use this page to re-upload any Remento recordings that failed to upload during your session.
          </RMText>

          {/* Button */}
          {state.type === RecordingRecoveryStateType.EMPTY && (
            <Button background="primary" onClick={handleRecover}>
              Retry
            </Button>
          )}
          {state.type === RecordingRecoveryStateType.RECOVERED && (
            <Button background="primary" onClick={handleGoToHome}>
              Return to Remento
            </Button>
          )}
        </RightCard>
      </PageContent>
    </RecordingLayout.Root>
  );
}

export function RecordingRecoveryPage() {
  return (
    <RementoPage type="empty">
      <InternalRecordingRecoveryPage />
    </RementoPage>
  );
}
